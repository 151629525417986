// Components
import { Link } from "react-router-dom";
// Hooks
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useFormatInput } from "../../hooks/useFormatInput";
import { useTranslation } from "react-i18next";
// Redux
import { useGetUserDataQuery } from "../../redux/services/userService";
import { selectCurrentUser } from "../../redux/auth/authSlice";

const Security = () => {
  const [email, setEmail] = useState("");
  const [password] = useState("*".repeat(16));
  const [phone, setPhone] = useState("");

  const { t } = useTranslation();

  const currentUser = useSelector(selectCurrentUser);
  const { data: userData } = useGetUserDataQuery(currentUser.id);

  const { formatPhone } = useFormatInput();

  useEffect(() => {
    if (userData) {
      setEmail(userData.email);
      setPhone(userData.phone);
    }
  }, [userData]);

  return (
    <div className="pt-sm-5 px-2">
      <div className="row justify-content-between align-items-center border-top border-bottom py-2 my-2">
        <h5 className="text-start text-decoration-underline">E-mail</h5>
        <div className="col text-start fs-5">
          <span>{email}</span>
        </div>
        <div className="col text-end">
          <Link
            to={`/dashboard/profile/email/edit`}
            className="btn btn-sm btn-primary py-2 px-3"
            name="button"
            type="button"
          >
            {t("Editar")}
          </Link>
        </div>
      </div>
      <div className="row justify-content-between align-items-center border-top border-bottom py-2 my-2">
        <h5 className="text-start text-decoration-underline">{t("Senha")}</h5>
        <div className="col text-start fs-5">
          <span>{password}</span>
        </div>
        <div className="col text-end">
          <Link
            to={`/dashboard/profile/password/edit`}
            className="btn btn-sm btn-primary py-2 px-3"
            name="button"
            type="button"
          >
            {t("Editar")}
          </Link>
        </div>
      </div>
      <div className="row justify-content-between align-items-center border-top border-bottom py-2 my-2">
        <h5 className="text-start text-decoration-underline">
          {t("Telefone")}
        </h5>
        <div className="col text-start fs-5">
          <span>{formatPhone(phone)}</span>
        </div>
        <div className="col text-end">
          <Link
            to={`/dashboard/profile/phone/edit`}
            className="btn btn-sm btn-primary py-2 px-3"
            name="button"
            type="button"
          >
            {t("Editar")}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Security;
