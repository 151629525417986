// Hooks
import { useState, useEffect } from "react";
// CSS
import "./Message.css";

const Message = ({ msg, type, timeout = true }) => {
  const [warningType, setWarningType] = useState(type);

  useEffect(() => {
    if (timeout) {
      setTimeout(() => {
        setWarningType("d-none");
      }, 4500);
    }
  });

  return (
    <div className={`message ${warningType}`}>
      <p>{msg}</p>
    </div>
  );
};

export default Message;
