// Hooks
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useFormatInput } from "../../hooks/useFormatInput";
import { useTranslation } from "react-i18next";
// Components
import Message from "../../components/General/Message";
import Loading from "../../components/General/Loading";
// Redux
import {
  useGetUserDataQuery,
  useUpdateUserMutation,
} from "../../redux/services/userService";
import { selectCurrentUser } from "../../redux/auth/authSlice";

const Profile = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("");
  const [clinic, setClinic] = useState("");
  const [cnpj, setCnpj] = useState("");
  const [cpf, setCpf] = useState("");
  const [phone, setPhone] = useState("");

  const { t } = useTranslation();

  const currentUser = useSelector(selectCurrentUser);
  const { data: userData } = useGetUserDataQuery(currentUser.id);
  const [
    updateUser,
    { isLoading: updateLoading, error: updateError, isSuccess: updateSuccess },
  ] = useUpdateUserMutation();

  const { formatCPF, formatCNPJ, cleanInput } = useFormatInput();

  useEffect(() => {
    if (userData) {
      setName(userData.name);
      setEmail(userData.email);
      setRole(userData.role);
      setClinic(userData.clinic);
      setCpf(userData.cpf);
      setCnpj(userData.cnpj);
      setPhone(userData.phone);
    }
  }, [userData]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = { name, role, cnpj: cleanInput(cnpj), clinic };
    await updateUser({ userId: currentUser.id, data });
  };

  return (
    <div className="py-sm-4">
      <form onSubmit={handleSubmit}>
        <div className="row d-flex justify-content-center align-items-center">
          <div className="col-md-6 mb-4">
            <div className="form-floating mb-3 text-start">
              <input
                className="form-control"
                value={email || ""}
                type="email"
                id="floatingEmail"
                readOnly
              />
              <label className="form-label" htmlFor="floatingEmail">
                Email
              </label>
            </div>
          </div>
          <div className="col-md-6 mb-4">
            <div className="form-floating mb-3 text-start">
              <input
                className="form-control"
                value={phone || ""}
                type="phone"
                id="floatingPhone"
                readOnly
              />
              <label className="form-label" htmlFor="floatingPhone">
                {t("Telefone")}
              </label>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 mb-4">
            <div className="form-floating mb-3 text-start">
              <input
                className="form-control"
                value={name || ""}
                onChange={(e) => setName(e.target.value)}
                type="text"
                id="floatingName"
              />
              <label className="form-label" htmlFor="floatingName">
                {t("Nome")}
              </label>
            </div>
          </div>
          <div className="col-md-6 mb-4">
            <div className="form-floating mb-3 text-start">
              <input
                className="form-control"
                value={formatCPF(cpf) || ""}
                type="text"
                id="floatingCpf"
                readOnly
              />
              <label className="form-label" htmlFor="floatingCpf">
                CPF
              </label>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4 mb-4">
            <div className="form-floating mb-3 text-start">
              <input
                className="form-control"
                value={clinic || ""}
                onChange={(e) => setClinic(e.target.value)}
                type="text"
                id="floatingClinic"
              />
              <label className="form-label" htmlFor="floatingClinic">
                {t("Clínica")}
              </label>
            </div>
          </div>
          <div className="col-md-4 mb-4">
            <div className="form-floating mb-3 text-start">
              <input
                className="form-control"
                value={role || ""}
                onChange={(e) => setRole(e.target.value)}
                type="text"
                id="floatingRole"
              />
              <label className="form-label" htmlFor="floatingRole">
                {t("Cargo")}
              </label>
            </div>
          </div>
          <div className="col-md-4 mb-4">
            <div className="form-floating mb-3 text-start">
              <input
                className="form-control"
                value={formatCNPJ(cnpj) || ""}
                onChange={(e) => setCnpj(e.target.value)}
                type="text"
                id="floatingCnpj"
              />
              <label className="form-label" htmlFor="floatingCnpj">
                CNPJ
              </label>
            </div>
          </div>
        </div>
        {!updateLoading && (
          <button
            className="btn btn-primary btn-md px-4 mb-2"
            name="button"
            type="submit"
          >
            {t("Atualizar")}
          </button>
        )}
        {updateLoading && <Loading />}
      </form>
      {updateError && <Message msg={updateError} type="error" />}
      {updateSuccess && (
        <Message msg={t("UpdateUserMessage")} type="success" />
      )}
    </div>
  );
};

export default Profile;
