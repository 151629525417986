// CSS
import "./App.css";
// Components
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import NavMenu from "./components/General/NavMenu";
import Footer from "./components/General/Footer";
import ScrollToTop from "./components/General/ScrollToTop";
import Loading from "./components/General/Loading";
// Hooks
import { lazy, Suspense } from "react";
import { useAuth } from "./hooks/useAuth";

const App = () => {
  const { auth } = useAuth();

  // Pages
  const Home = lazy(() => import("./pages/Home/Home"));
  const Services = lazy(() => import("./pages/Services/Services"));
  const Plans = lazy(() => import("./pages/Plans/Plans"));
  const Articles = lazy(() => import("./pages/Articles/Articles"));
  const Contact = lazy(() => import("./pages/Contact/Contact"));
  const About = lazy(() => import("./pages/About/About"));
  const ServiceDescription = lazy(() =>
    import("./pages/ServiceDescription/ServiceDescription")
  );
  const SignUp = lazy(() => import("./pages/SignUp/SignUp"));
  const Login = lazy(() => import("./pages/Login/Login"));
  const Dashboard = lazy(() => import("./pages/Dashboard/Dashboard"));
  const BudgetForm = lazy(() => import("./pages/BudgetForm/BudgetForm"));
  const AnalysisCreateForm = lazy(() =>
    import("./pages/AnalysisForm/AnalysisCreateForm")
  );
  const AnalysisEditForm = lazy(() =>
    import("./pages/AnalysisForm/AnalysisEditForm")
  );
  const CalibrationCreateForm = lazy(() =>
    import("./pages/CalibrationForm/CalibrationCreateForm")
  );
  const CalibrationEditForm = lazy(() =>
    import("./pages/CalibrationForm/CalibrationEditForm")
  );
  const Analyses = lazy(() => import("./pages/Analyses/Analyses"));
  const Checkout = lazy(() => import("./pages/Checkout/Checkout"));
  const Calculator = lazy(() => import("./pages/Calculator/Calculator"));
  const CalculatorManual = lazy(() =>
    import("./pages/CalculatorManual/CalculatorManual")
  );
  const EditUserData = lazy(() => import("./pages/EditUserData/EditUserData"));
  const NotFound = lazy(() => import("./pages/NotFound/NotFound"));
  const VerifyEmail = lazy(() => import("./pages/VerifyEmail/VerifyEmail"));
  const ForgotPasswordRequest = lazy(() =>
    import("./pages/ForgotPasswordRequest/ForgotPasswordRequest")
  );
  const ForgotPasswordChange = lazy(() =>
    import("./pages/ForgotPasswordChange/ForgotPasswordChange")
  );
  // Components
  const Product = lazy(() => import("./components/Services/Product"));

  return (
    <div className="App">
      <BrowserRouter>
        <NavMenu />
        <ScrollToTop>
          <div className="set_page_size">
            <Suspense fallback={<Loading />}>
              <Routes>
                <Route path="/" element={<Home />}></Route>
                <Route
                  path="/service-info/:id"
                  element={<ServiceDescription />}
                ></Route>
                <Route path="/services" element={<Services />}></Route>
                <Route path="/services/:id/hire" element={<Product />}></Route>
                <Route path="/plans" element={<Plans />}></Route>
                <Route path="/articles" element={<Articles />}></Route>
                <Route path="/contact" element={<Contact />}></Route>
                <Route path="/about" element={<About />}></Route>
                <Route
                  path="/signup"
                  element={
                    !auth ? <SignUp /> : <Navigate to="/dashboard/profile" />
                  }
                ></Route>
                <Route
                  path="/login"
                  element={
                    !auth ? <Login /> : <Navigate to="/dashboard/profile" />
                  }
                ></Route>
                <Route
                  path="/dashboard/:component"
                  element={auth ? <Dashboard /> : <Login />}
                ></Route>
                <Route
                  path="/dashboard/my-services/:orderId/analysis-form/create"
                  element={auth ? <AnalysisCreateForm /> : <Login />}
                ></Route>
                <Route
                  path="/dashboard/my-services/:orderId/analyses"
                  element={auth ? <Analyses /> : <Login />}
                ></Route>
                <Route
                  path="/dashboard/my-services/:orderId/analysis-form/:analysisId/edit"
                  element={auth ? <AnalysisEditForm /> : <Login />}
                ></Route>
                <Route
                  path="/dashboard/my-calibrations/add"
                  element={auth ? <CalibrationCreateForm /> : <Login />}
                ></Route>
                <Route
                  path="/dashboard/my-calibrations/:id/edit"
                  element={auth ? <CalibrationEditForm /> : <Login />}
                ></Route>
                <Route
                  path="/services/:id/hire/budget-form"
                  element={auth ? <BudgetForm /> : <Login />}
                ></Route>
                <Route
                  path="/plans/:id/checkout"
                  element={auth ? <Checkout /> : <Login />}
                ></Route>
                <Route
                  path="/dashboard/my-signatures/benefits/calculator"
                  element={auth ? <Calculator /> : <Login />}
                ></Route>
                <Route
                  path="/dashboard/my-signatures/benefits/calculator/manual"
                  element={auth ? <CalculatorManual /> : <Login />}
                ></Route>
                <Route
                  path="/dashboard/profile/:data/edit"
                  element={auth ? <EditUserData /> : <Login />}
                ></Route>
                <Route
                  path="/users/:id/email-confirm"
                  element={<VerifyEmail />}
                ></Route>
                <Route
                  path="/users/:id/reset-password"
                  element={<ForgotPasswordChange />}
                ></Route>
                <Route
                  path="/forgot-password/request"
                  element={<ForgotPasswordRequest />}
                ></Route>
                <Route path="*" element={<NotFound />}></Route>
              </Routes>
            </Suspense>
          </div>
        </ScrollToTop>
        <Footer />
      </BrowserRouter>
    </div>
  );
};

export default App;
