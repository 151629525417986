import partnerHucffLogo from "./logo_hucff.png";
import coppeLogo from "./Logo_COPPE_-_UFRJ.jpg";
import incubadoraLogo from "./logo_incubadora.png";
import faperjLogo from "./logo_faperj_vinho_completo.png";
import fundepLogo from "./fundep_logo.png";

const partnersIndex = [
  {
    name: "FAPERJ",
    logo: faperjLogo,
    link: "https://www.faperj.br/",
  },
  {
    name: "FUNDEP",
    logo: fundepLogo,
    link: "https://www.fundep.ufmg.br/",
  },
  {
    name: "COPPE-UFRJ",
    logo: coppeLogo,
    link: "https://www.coppe.ufrj.br/",
  },
  {
    name: "Incubadora de empresas UFRJ",
    logo: incubadoraLogo,
    link: "https://incubadora.coppe.ufrj.br/",
  },
  {
    name: "HUCFF",
    logo: partnerHucffLogo,
    link: "http://www.hucff.ufrj.br/",
  },
];

export default partnersIndex;
