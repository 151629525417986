// Redux
import { createSlice } from "@reduxjs/toolkit";
// Components
import Profile from "../../components/Dashboard/Profile";
import MyServices from "../../components/Dashboard/MyServices";
import MySignatures from "../../components/Dashboard/MySignatures";
import MyCalibrations from "../../components/Dashboard/MyCalibrations";
import Security from "../../components/Dashboard/Security";
import Notifications from "../../components/Dashboard/Notifications";

const initialState = {
  components: [
    { link: "/dashboard/profile", name: "Perfil", toRender: <Profile /> },
    {
      link: "/dashboard/my-services",
      name: "Meus Serviços",
      toRender: <MyServices />,
    },
    {
      link: "/dashboard/my-calibrations",
      name: "Minhas Calibrações",
      toRender: <MyCalibrations />,
    },
    {
      link: "/dashboard/my-signatures",
      name: "Minhas Assinaturas",
      toRender: <MySignatures />,
    },
    { link: "/dashboard/security", name: "Segurança", toRender: <Security /> },
    {
      link: "/dashboard/notifications",
      name: "Notificações",
      toRender: <Notifications />,
    },
  ],
};

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {},
});

export default dashboardSlice.reducer;
