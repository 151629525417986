import { apiSlice } from "../api/apiSlice";
import { requestConfig } from "../config/config";

export const authService = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    register: builder.mutation({
      query: (credentials) =>
        requestConfig("/users/register/", "POST", credentials),
    }),
    login: builder.mutation({
      query: (credentials) =>
        requestConfig("/users/login/", "POST", credentials),
    }),
    logout: builder.mutation({
      query: () => requestConfig("/users/auth/token/logout/", "POST", null),
    }),
    changePassword: builder.mutation({
      query: ({ userId, data }) =>
        requestConfig(`/users/${userId}/change_password/`, "POST", data, null),
    }),
    forgotPasswordRequest: builder.mutation({
      query: (email) =>
        requestConfig("/users/auth/reset_password/", "POST", email, null),
    }),
    forgotPasswordChange: builder.mutation({
      query: ({ userId, data }) =>
        requestConfig(
          `/users/${userId}/reset_password_confirm/`,
          "POST",
          data,
          null
        ),
    }),
  }),
});

export const {
  useRegisterMutation,
  useLoginMutation,
  useLogoutMutation,
  useChangePasswordMutation,
  useForgotPasswordRequestMutation,
  useForgotPasswordChangeMutation,
} = authService;
