import { useState, useEffect } from "react";
import { termsLinks } from "../img/index";
import { useReadLocalStorage } from "usehooks-ts";

export const usePickTerm = () => {
  const language = useReadLocalStorage("DosimagemLanguage")

  const [termLink, setTermLink] = useState("");

  useEffect(() => {
    if (language) {
      if (language === "en-US") {
        setTermLink(termsLinks.termEn);
      } else if (language === "es") {
        setTermLink(termsLinks.termEs);
      } else {
        setTermLink(termsLinks.termPt);
      }
    }
  }, [language]);

  return termLink;
};
