import downloadReport from "./download.png";
import nuclearClinic from "./nuclear_clinic.png";
import processingAnalysis from "./processing.png";

const workIndex = [
  {
    title: "HowWeWorkStep1",
    img: nuclearClinic,
    body: "HowWeWorkText1",
  },
  {
    title: "HowWeWorkStep2",
    img: processingAnalysis,
    body: "HowWeWorkText2",
  },
  {
    title: "HowWeWorkStep3",
    img: downloadReport,
    body: "HowWeWorkText3",
  },
];

export default workIndex;
