// Components
import { Link } from "react-router-dom";
import Loading from "../../components/General/Loading";
import Message from "../General/Message";
import CalibrationPagination from "../Pagination/CalibrationPagination";
import DeleteConfModal from "../General/DeleteConfModal";
// Hooks
import { useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
// Redux
import {
  useGetCalibrationsQuery,
  useDeleteCalibrationMutation,
} from "../../redux/services/calibrationService";
import { selectCurrentUser } from "../../redux/auth/authSlice";
// Icons
import { IoAddCircleOutline } from "react-icons/io5";

const MyCalibrations = () => {
  const [show, setShow] = useState(false);
  const [objectToDelete, setObjectToDelete] = useState("");

  const { t } = useTranslation();

  const currentUser = useSelector(selectCurrentUser);
  const {
    data: calibrations = [],
    isLoading,
    error: getError,
  } = useGetCalibrationsQuery(currentUser.id);
  const [deleteCalibration, { isSuccess, error: deleteError }] =
    useDeleteCalibrationMutation();

  const handleDelete = (id) => {
    setShow(true);
    setObjectToDelete({ userId: currentUser.id, calibrationId: id });
  };

  return (
    <>
      <DeleteConfModal
        show={show}
        setShow={setShow}
        objectToDelete={objectToDelete}
        deleteRequestFunction={deleteCalibration}
      />
      {!calibrations && !getError ? (
        <div className="mb-1">
          <Link
            to="/dashboard/my-calibrations/add"
            className="btn btn-success px-3 disabled"
          >
            <IoAddCircleOutline size={20} />
          </Link>
        </div>
      ) : (
        <div className="mb-1">
          <Link
            to="/dashboard/my-calibrations/add"
            className="btn btn-success px-3"
          >
            <IoAddCircleOutline size={20} />
          </Link>
        </div>
      )}
      {!calibrations && !getError && (
        <Message msg="Serviço indisponível!" type="error" />
      )}
      {isLoading && <Loading />}
      {getError && <Message msg={getError} type="error" />}
      {isSuccess && (
        <Message msg={t("DeleteCalibrationMessage")} type="success" />
      )}
      {!isLoading && calibrations && calibrations.length === 0 && (
        <Message
          msg={t("EmptyCalibrationsMessage")}
          type="warning"
          timeout={false}
        />
      )}
      <div>
        {deleteError && <Message msg={deleteError} type="error" />}
        {!isLoading && calibrations && calibrations.length !== 0 && (
          <CalibrationPagination
            data={calibrations}
            itemsPerPage={2}
            handleDelete={handleDelete}
          />
        )}
      </div>
    </>
  );
};

export default MyCalibrations;
