// Hooks
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
// Components
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
// Icons
import { BiEdit, BiTrash } from "react-icons/bi";

const CalibrationPagination = ({ data, itemsPerPage, handleDelete }) => {
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffSet, setItemOffSet] = useState(0);

  const { t } = useTranslation();

  useEffect(() => {
    const endOffset = itemOffSet + itemsPerPage;
    setCurrentItems(data.slice(itemOffSet, endOffset));
    setPageCount(Math.ceil(data.length / itemsPerPage));
  }, [itemOffSet, itemsPerPage, data]);

  const handlePageClick = (e) => {
    const newOffSet = (e.selected * itemsPerPage) % data.length;
    setItemOffSet(newOffSet);
  };

  return (
    <>
      {currentItems.map(
        ({
          id,
          calibrationName,
          syringeActivity,
          residualSyringeActivity,
          measurementDatetime,
          isotope,
          phantomVolume,
          acquisitionTime,
        }) => (
          <div key={id} className="border p-2 m-2">
            <div className="row">
              <div className="col-md-6">
                <p className="text-start fs-5">
                  <span className="fw-bold">{t("Nome")}: </span>
                  {calibrationName}
                </p>
              </div>
              <div className="col-md-6 text-end">
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip>{t("Editar")}</Tooltip>}
                >
                  <Link
                    to={`/dashboard/my-calibrations/${id}/edit`}
                    className="btn btn-secondary btn-sm mx-1"
                  >
                    <BiEdit size={20} />
                  </Link>
                </OverlayTrigger>
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip>{t("Deletar")}</Tooltip>}
                >
                  <button
                    className="btn btn-danger btn-sm mx-1"
                    onClick={() => handleDelete(id)}
                  >
                    <BiTrash size={20} />
                  </button>
                </OverlayTrigger>
              </div>
            </div>
            <div className="row text-start">
              <div className="col-md-6">
                <span className="fw-bold">{t("Atividade da Seringa")}: </span>
                {syringeActivity} MBq
              </div>
              <div className="col-md-6">
                <span className="fw-bold">
                  {t("Atividade Residual na Seringa")}:{" "}
                </span>
                {residualSyringeActivity} MBq
              </div>
            </div>
            <div className="row text-start">
              <div className="col-md-6">
                <span className="fw-bold">{t("Data e Hora da Medição")}: </span>
                {measurementDatetime}
              </div>
              <div className="col-md-6">
                <span className="fw-bold">{t("Isótopo")}: </span>
                {isotope}
              </div>
            </div>
            <div className="row text-start">
              <div className="col-md-6">
                <span className="fw-bold">{t("Volume do Fantoma")}: </span>
                {phantomVolume} cm<sup>3</sup>
              </div>
              <div className="col-md-6">
                <span className="fw-bold">{t("Tempo de Aquisição")}: </span>
                {acquisitionTime} s
              </div>
            </div>
          </div>
        )
      )}
      <div className="mt-5 mt-lg-4">
        <nav className="position-absolute bottom-0 start-50 translate-middle-x">
          <ReactPaginate
            breakLabel="..."
            nextLabel=">"
            onPageChange={handlePageClick}
            pageRangeDisplayed={2}
            pageCount={pageCount}
            previousLabel="<"
            renderOnZeroPageCount={null}
            containerClassName="pagination justify-content-center"
            pageLinkClassName="page-link"
            previousLinkClassName="page-link"
            nextLinkClassName="page-link"
          />
        </nav>
      </div>
    </>
  );
};

export default CalibrationPagination;
