// Redux
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
// Request
import { baseUrl } from "../config/config";
// Slice
import { logout } from "../auth/authSlice";

const language = localStorage.getItem("DosimagemLanguage")?.replace(/"/g, '').replace(/'/g, '') || "pt-BR";

const baseQuery = fetchBaseQuery({
  baseUrl,
  credentials: "include",
  prepareHeaders: (headers) => {
    headers.set("Accept-Language", language);
    return headers
  },
});

const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);

  const error = result?.error?.status;

  if (error === 401) {
    // Send refresh token to get a new access token
    const refreshResult = await baseQuery(
      "/users/auth/token/refresh/",
      api,
      extraOptions
    );

    if (refreshResult?.data) {
      // Retry the original query with new access token
      result = await baseQuery(args, api, extraOptions);
      return result;
    } else {
      await baseQuery("/users/auth/token/logout/", api, extraOptions);
      api.dispatch(logout());
    }
  }

  if (result?.error?.data) {
    return { error: result?.error?.data.errors[0] };
  }

  return result;
};

export const apiSlice = createApi({
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({}),
});
