// Hooks
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
// Components
import Message from "../General/Message";
import Loading from "../General/Loading";
import MyServicesPagination from "../Pagination/MyServicesPagination";
import { Link } from "react-router-dom";
// Redux
import { useGetOrdersQuery } from "../../redux/services/orderService";
import { selectCurrentUser } from "../../redux/auth/authSlice";

const MyServices = () => {
  const currentUser = useSelector(selectCurrentUser);

  const { t } = useTranslation();

  const {
    isLoading,
    error,
    data: orders = [],
    isSuccess,
  } = useGetOrdersQuery(currentUser.id);

  return (
    <>
      {isLoading && <Loading />}
      {error && <Message msg={error} type="error" />}
      {isSuccess && (
        <>
          {!orders && !error && (
            <Message msg="Serviço indisponível!" type="error" timeout={false} />
          )}
          {!isLoading && orders && orders.length === 0 && (
            <div className="message warning">
              <p>
                {t("EmptyServicesMessage")}{" "}
                <span>
                  <Link className="text-warning" to="/services">
                    {t("orçamento")}
                  </Link>
                  .
                </span>
              </p>
            </div>
          )}
          {!isLoading && orders && orders.length !== 0 && (
            <MyServicesPagination data={orders} itemsPerPage={6} />
          )}
        </>
      )}
    </>
  );
};

export default MyServices;
