// Redux
import { createSlice } from "@reduxjs/toolkit";
// Cookies
import Cookies from "js-cookie";

const initialState = {
  user:
    {
      id: Cookies.get("dosiCookieId"),
      isStaff: Cookies.get("dosiCookieStaff") === "false" ? false : true,
    } || {},
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setCredentials: (state, action) => {
      const user = { id: action.payload.id, isStaff: action.payload.isStaff };
      Cookies.set("dosiCookieId", user.id, { expires: 7 });
      Cookies.set("dosiCookieStaff", user.isStaff, { expires: 7 });
      state.user = user;
    },
    logout: (state) => {
      state.user = {};
      Cookies.remove("dosiCookieId");
      Cookies.remove("dosiCookieStaff");
    },
  },
});

export const selectCurrentUser = (state) => state.auth.user;

export const { setCredentials, logout } = authSlice.actions;
export default authSlice.reducer;
