import { apiSlice } from "../api/apiSlice";
import { requestConfig } from "../config/config";

const usersUrl = "/users";

export const userService = apiSlice.injectEndpoints({
  tagTypes: ["User"],
  endpoints: (builder) => ({
    getUserData: builder.query({
      query: (userId) => requestConfig(`${usersUrl}/${userId}/`, "GET", null),
      providesTags: ["User"],
    }),
    updateUser: builder.mutation({
      query: ({ userId, data }) =>
        requestConfig(`${usersUrl}/${userId}/`, "PATCH", data),
      invalidatesTags: ["User"],
    }),
    updateUserEmail: builder.mutation({
      query: ({ userId, email }) =>
        requestConfig(`${usersUrl}/${userId}/email/`, "PATCH", { email }),
      invalidatesTags: ["User"],
    }),
  }),
});

export const {
  useGetUserDataQuery,
  useUpdateUserMutation,
  useUpdateUserEmailMutation,
} = userService;
