import { apiSlice } from "../api/apiSlice";
import { requestConfig } from "../config/config";

const calibrationService = apiSlice.injectEndpoints({
  tagTypes: ["Calibration", "Notification"],
  endpoints: (builder) => ({
    getCalibrations: builder.query({
      query: (userId) =>
        requestConfig(`users/${userId}/calibrations/`, "GET", null),
      transformResponse: (response) => response.row,
      providesTags: ["Calibration", "Notification"],
    }),
    getCalibrationById: builder.query({
      query: ({ userId, calibrationId }) =>
        requestConfig(
          `/users/${userId}/calibrations/${calibrationId}/`,
          "GET",
          null
        ),
      providesTags: ["Calibration", "Notification"],
    }),
    createCalibration: builder.mutation({
      query: ({ userId, calibration }) =>
        requestConfig(
          `/users/${userId}/calibrations/`,
          "POST",
          calibration,
          true
        ),
      invalidatesTags: ["Calibration", "Notification"],
    }),
    updateCalibration: builder.mutation({
      query: ({ userId, calibrationId, calibration }) =>
        requestConfig(
          `/users/${userId}/calibrations/${calibrationId}/`,
          "PUT",
          calibration,
          true
        ),
      invalidatesTags: ["Calibration", "Notification"],
    }),
    deleteCalibration: builder.mutation({
      query: ({ userId, calibrationId }) =>
        requestConfig(
          `/users/${userId}/calibrations/${calibrationId}`,
          "DELETE",
          null
        ),
      invalidatesTags: ["Calibration", "Notification"],
    }),
    getCalibrationIsotopes: builder.query({
      query: () =>
        requestConfig(`/isotopes`, "GET", null, null, { q: "dosimetry" }),
      transformResponse: (response) => response.row,
    }),
  }),
});

export const {
  useGetCalibrationsQuery,
  useGetCalibrationByIdQuery,
  useCreateCalibrationMutation,
  useUpdateCalibrationMutation,
  useDeleteCalibrationMutation,
  useGetCalibrationIsotopesQuery,
} = calibrationService;
