export const baseUrl =
//   `${process.env.REACT_APP_API}/api/v1` || "https://191.252.184.84/api/v1";
   "https://www.dosimagem.com/api/v1";

export const requestConfig = (
  url,
  method,
  data,
  image = null,
  params = null
) => {
  let config;

  url = url.endsWith("/") ? url : `${url}/`;

  if (image) {
    config = {
      url,
      method,
      body: data,
      params,
    };
  } else if (method === "DELETE" && data === null) {
    config = {
      url,
      method,
      params,
    };
  } else if (method !== "GET") {
    config = {
      url,
      method,
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
      params,
    };
  } else {
    config = {
      url,
      method,
      params,
    };
  }

  return config;
};
