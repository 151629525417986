import articlesIndex from "./articles/articlesIndex";
import carouselIndex from "./carousel_slides/carouselIndex";
import {
  logoName,
  logoImg,
  logoAzulForm,
  isotipoAzul,
  notFoundImg,
} from "./logos/logosIndex";
import { services, companyPros, teamHome } from "./home_imgs/homeIndex";
import partnersIndex from "./partners/partnersIndex";
import teamIndex from "./team_photos/teamIndex";
import testimonialsIndex from "./testimonial_images/testimonialsIndex";
import workIndex from "./how_we_work/workIndex";

const plans = [
  {
    id: 1,
    title: "Plan1Title",
    price: 60,
    discount: 0,
    trialTime: 30,
    modality: "monthly",
    slogan: "Plan1Slogan",
    benefits: ["Plan1Benefit1"],
  },
  {
    id: 2,
    title: "Plan2Title",
    price: 600,
    discount: 380,
    trialTime: 30,
    modality: "yearly",
    slogan: "Plan2Slogan",
    benefits: ["Plan2Benefit1"],
  },
];

const termsLinks = {
  termPt: "https://dosimagem-estaticos.s3.sa-east-1.amazonaws.com/termos-de-uso/termos_de_uso_e_politica_de_privacidade_pt.pdf",
  termEn: "",
  termEs: "https://dosimagem-estaticos.s3.sa-east-1.amazonaws.com/termos-de-uso/termos_de_uso_e_politica_de_privacidade_es.pdf",
};

export {
  articlesIndex,
  carouselIndex,
  logoName,
  logoImg,
  logoAzulForm,
  isotipoAzul,
  services,
  companyPros,
  partnersIndex,
  testimonialsIndex,
  teamIndex,
  plans,
  teamHome,
  notFoundImg,
  workIndex,
  termsLinks,
};
