import hucffLogo from "./hucff.png";
import ipsgLogo from "./ipsg.png";

const testimonialsIndex = [
  {
    logo: hucffLogo,
    text: "HucffTestimonialText",
    person: `Sergio Augusto Lopes de Souza`,
    personTitle: "HucffTestimonialPersonTitle",
  },
  {
    logo: ipsgLogo,
    text: "IpsgTestimonialText",
    person: `Sylvia Thomas, MD, PhD`,
    personTitle: "IpsgTestimonialPersonTitle",
  },
];

export default testimonialsIndex;
