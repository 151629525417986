// Hooks
import { useState, useEffect } from "react";
import { useFormatInput } from "../../hooks/useFormatInput";
import { useTranslation } from "react-i18next";
// Components
import { Accordion, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
// Icons
import { IoAddCircleOutline } from "react-icons/io5";
import { MdPayment } from "react-icons/md";
import { RiRefreshLine, RiBillFill } from "react-icons/ri";
import { AiOutlineInfoCircle } from "react-icons/ai";

const MyServicesPagination = ({ data, itemsPerPage }) => {
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffSet, setItemOffSet] = useState(0);

  const { t } = useTranslation();

  useEffect(() => {
    const endOffset = itemOffSet + itemsPerPage;
    setCurrentItems(data.slice(itemOffSet, endOffset));
    setPageCount(Math.ceil(data.length / itemsPerPage));
  }, [itemOffSet, itemsPerPage, data]);

  const handlePageClick = (e) => {
    const newOffSet = (e.selected * itemsPerPage) % data.length;
    setItemOffSet(newOffSet);
  };

  const { formatDateToBr } = useFormatInput();

  return (
    <>
      <Accordion>
        {currentItems.map(
          ({
            id,
            serviceName,
            quantityOfAnalyzes,
            remainingOfAnalyzes,
            analysisStatus,
            statusPayment,
            price,
            createdAt,
            billUrl,
          }) => (
            <Accordion.Item key={id} eventKey={id}>
              <Accordion.Header className="row justify-content-evenly">
                <div className="col-6">{`${t("Serviço")}: ${t(
                  serviceName
                )}`}</div>
                <div className="col-6">{`${t("Data")}: ${formatDateToBr(
                  createdAt
                )}`}</div>
              </Accordion.Header>
              <Accordion.Body className="row justify-content-evenly">
                <div className="row my-1">
                  <div className="col-md-4 my-2">{`${t(
                    "Preço"
                  )}: ${price.toLocaleString("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                  })}`}</div>
                  <div className="col-md-4 my-2">{`${t(
                    "Total de análises"
                  )}: ${quantityOfAnalyzes}`}</div>
                  <div className="col-md-4 my-2">{`${t("Análises enviadas")}: ${
                    quantityOfAnalyzes - remainingOfAnalyzes
                  }`}</div>
                  <div className="col-md-6 my-2">
                    <MdPayment size={28} className="mx-2" />
                    {t(statusPayment)}
                  </div>
                  <div className="col-md-6 my-2">
                    <RiRefreshLine size={28} className="mx-2" />
                    {`${(
                      (analysisStatus.concluded / quantityOfAnalyzes) *
                      100
                    ).toFixed(0)} %`}
                  </div>
                </div>
                <div className="row justify-content-center m-2">
                  <div className="col">
                    {billUrl !== null && statusPayment !== "Confirmado" ? (
                      <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip>{t("Boleto")}</Tooltip>}
                      >
                        <a
                          className="btn btn-dark px-2"
                          href={billUrl}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <RiBillFill size={24} />
                        </a>
                      </OverlayTrigger>
                    ) : (
                      <a
                        className="btn btn-dark px-2 disabled"
                        href={billUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <RiBillFill size={24} />
                      </a>
                    )}
                  </div>
                  <div className="col">
                    {statusPayment === "Confirmado" &&
                    remainingOfAnalyzes !== 0 ? (
                      <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip>{t("Adicionar")}</Tooltip>}
                      >
                        <Link
                          to={`/dashboard/my-services/${id}/analysis-form/create`}
                          className="btn btn-success px-2"
                        >
                          <IoAddCircleOutline size={24} />
                        </Link>
                      </OverlayTrigger>
                    ) : (
                      <Link to="" className="btn btn-success disabled px-2">
                        <IoAddCircleOutline size={24} />
                      </Link>
                    )}
                  </div>
                  <div className="col">
                    {statusPayment === "Confirmado" ? (
                      <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip>{t("Detalhes")}</Tooltip>}
                      >
                        <Link
                          to={`/dashboard/my-services/${id}/analyses`}
                          className="btn btn-secondary px-2"
                        >
                          <AiOutlineInfoCircle size={24} />
                        </Link>
                      </OverlayTrigger>
                    ) : (
                      <Link to={""} className="btn btn-secondary px-2 disabled">
                        <AiOutlineInfoCircle size={24} />
                      </Link>
                    )}
                  </div>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          )
        )}
      </Accordion>
      <div className="mt-5 mt-lg-4">
        <nav className="position-absolute bottom-0 start-50 translate-middle-x">
          <ReactPaginate
            breakLabel="..."
            nextLabel=">"
            onPageChange={handlePageClick}
            pageRangeDisplayed={2}
            pageCount={pageCount}
            previousLabel="<"
            renderOnZeroPageCount={null}
            containerClassName="pagination justify-content-center"
            pageLinkClassName="page-link"
            previousLinkClassName="page-link"
            nextLinkClassName="page-link"
          />
        </nav>
      </div>
    </>
  );
};

export default MyServicesPagination;
