// Components
import { Modal } from "react-bootstrap";

const DeleteConfModal = ({
  show,
  setShow,
  objectToDelete,
  deleteRequestFunction,
}) => {
  const handleDelete = async () => {
    await deleteRequestFunction(objectToDelete);
    setShow(false);
  };

  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      animation={true}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title>
          <h4>{"Quer mesmo deletar esse item?"}</h4>
        </Modal.Title>
      </Modal.Header>
      <Modal.Footer>
        <div className="mx-auto">
          <button className="btn btn-danger m-2 px-4" onClick={handleDelete}>
            Deletar
          </button>
          <button
            className="btn btn-primary m-2 px-4"
            onClick={() => setShow(false)}
          >
            Fechar
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteConfModal;
