// Components
import { NavLink } from "react-router-dom";
// Hooks
import { useAuth } from "../../hooks/useAuth";
import { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useLocalStorage } from "usehooks-ts";
// Redux
import { selectCurrentUser } from "../../redux/auth/authSlice";
import { useGetNotificationsQuery } from "../../redux/services/notificationService";
import { useLogoutMutation } from "../../redux/services/authService";
import { logout as frontLogout } from "../../redux/auth/authSlice";
// CSS
import styles from "./NavMenu.module.css";
// Images
import { BiUserCircle, BiBell } from "react-icons/bi";
import { IoIosLogOut } from "react-icons/io";
import { logoAzulForm } from "../../img/index";
// Language
import i18next from "i18next";

const NavMenu = () => {
  const [language, setLanguage] = useLocalStorage("DosimagemLanguage", "pt-BR");

  const { auth } = useAuth();
  const [skip, setSkip] = useState(true);
  const [notificationsNumber, setNotificationsNumber] = useState("");

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const currentUser = useSelector(selectCurrentUser);

  useEffect(() => {
    if (currentUser.id) {
      setSkip(false);
    }
  }, [currentUser]);

  const { data: notifications = [] } = useGetNotificationsQuery(
    currentUser.id,
    { skip }
  );
  const [logout, { isSuccess, error }] = useLogoutMutation();

  useEffect(() => {
    const uncheckedNotifications = notifications.filter(
      (notification) => !notification.checked
    );
    setNotificationsNumber(
      uncheckedNotifications.length ? uncheckedNotifications.length : ""
    );
  }, [notifications]);

  const navLinks = [
    { name: "Home", url: "/", position: "left" },
    { name: "Serviços", url: "/services", position: "left" },
    { name: "Planos", url: "/plans", position: "left" },
    { name: "Artigos", url: "/articles", position: "left" },
    { name: "Contato", url: "/contact", position: "left" },
    { name: "Sobre", url: "/about", position: "left" },
    { name: "Login", url: "/login", position: "right" },
    { name: "Cadastro", url: "/signup", position: "right" },
  ];

  const languages = [
    { flag: "🇧🇷", value: "pt-BR" },
    { flag: "🇺🇸", value: "en-US" },
    { flag: "🇪🇸", value: "es" },
  ];

  useEffect(() => {
    i18next.changeLanguage(language);
  }, [language]);

  const navButton = useRef(null);
  const linksContainer = useRef(null);

  const collapseNav = () => {
    navButton.current.classList.add("collapsed");
    linksContainer.current.classList.remove("show");
  };

  useEffect(() => {
    if (isSuccess && !error) {
      dispatch(frontLogout());
    }
  }, [isSuccess, error, dispatch]);

  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light sticky-top">
      <div className="container-fluid">
        <button
          ref={navButton}
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div
          className="collapse navbar-collapse"
          id="navbarSupportedContent"
          ref={linksContainer}
        >
          <NavLink to="/" className="navbar-brand mt-2 mt-lg-0" end>
            <img
              src={logoAzulForm}
              alt="dosimagem-logo"
              loading="lazy"
              className="img-fluid"
              style={{ maxHeight: "3em" }}
            />
          </NavLink>
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            {navLinks.map(
              ({ name, url, position }, idx) =>
                position === "left" && (
                  <li className="nav-item" key={idx}>
                    <NavLink
                      className="nav-link"
                      to={url}
                      onClick={collapseNav}
                      end
                    >
                      {t(name)}
                    </NavLink>
                  </li>
                )
            )}
                  <li className="nav-item">
                     <a href="https://blog.dosimagem.com" rel="noopener noreferrer" className="nav-link">
                      Blog
                     </a>
                  </li>
          </ul>
        </div>
        <div className="d-flex align-items-center">
          {navLinks.map(
            ({ name, url, position }, idx) =>
              !auth &&
              position === "right" && (
                <NavLink
                  className={styles.link}
                  to={url}
                  key={idx}
                  onClick={collapseNav}
                >
                  {t(name)}
                </NavLink>
              )
          )}
          {auth && (
            <>
              <NavLink
                className="text-reset mx-1 position-relative"
                to="/dashboard/notifications"
                onClick={collapseNav}
              >
                <BiBell size={28} />
                {notificationsNumber === 0 && <span></span>}
                {notificationsNumber > 0 && notificationsNumber <= 99 && (
                  <span
                    className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger"
                    style={{ fontSize: ".55em" }}
                  >
                    {notificationsNumber}
                  </span>
                )}
                {notificationsNumber > 99 && (
                  <span
                    className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger"
                    style={{ fontSize: ".5em" }}
                  >
                    99+
                  </span>
                )}
              </NavLink>
              <NavLink
                className="text-reset mx-1"
                to="/dashboard/profile"
                onClick={collapseNav}
              >
                <BiUserCircle size={28} />
              </NavLink>
              <button
                className="btn btn-outline-danger btn-sm mx-1"
                onClick={async () => await logout()}
              >
                <span className="d-flex justify-content-center align-items-center">
                  <IoIosLogOut />
                  &nbsp;Logout
                </span>
              </button>
            </>
          )}
          <select
            className="form-select"
            value={language}
            onChange={(e) => setLanguage(e.target.value)}
          >
            {languages.map(({ flag, value }, idx) => (
              <option key={idx} value={value}>
                {flag}
              </option>
            ))}
          </select>
        </div>
      </div>
    </nav>
  );
};

export default NavMenu;
