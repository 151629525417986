import spectPerfArticle from "./spect_performance_evaluation.png";
import precliRadArticle from "./preclinical_radiation.png";
import approachRSVArticle from "./approach_rsv.png";
import individualDoseArticle from "./individual_dose.png";

const articles = [
  {
    img: spectPerfArticle,
    title:
      "SPECT performance evaluation on image of Yttrium 90 - Bremsstrahlung using Monte Carlo simulation",
    abstract: "SpectPerfArticleAbstract",
    link: "https://www.sciencedirect.com/science/article/abs/pii/S096980432030600X?via%3Dihub",
  },
  {
    img: precliRadArticle,
    title:
      "Preclinical radiation internal dosimetry in the development of new radiopharmaceuticals using GATE Monte Carlo simulation",
    abstract: "PrecliRadArticleAbstract",
    link: "https://www.sciencedirect.com/science/article/abs/pii/S0969806X19310321",
  },
  {
    img: approachRSVArticle,
    title:
      "A new approach for radiosynoviorthesis: A dose-optimized planning method based on Monte Carlo simulation and synovial measurement using 3D slicer and MRI",
    abstract: "ApproachRSVArticleAbstract",
    link: "https://aapm.onlinelibrary.wiley.com/doi/10.1002/mp.12276",
  },
  {
    img: individualDoseArticle,
    title:
      "Individual dose planning in radiosynoviorthesis treatment: Step by step",
    abstract: "IndividualDoseArticleAbstract",
    link: "https://www.sciencedirect.com/science/article/abs/pii/S0969804320303067",
  },
];

export default articles;
