import mirtaImg from "./Mirta_Torres.jpg";
import nicolasImg from "./Nicolas_da_Matta.png";
import harlleyImg from "./Harlley_Dantas.jpg";
import henriqueImg from "./Henrique_Condor.jpg";
import nathaliaImg from "./Nathalia_Leite.jpg";
import mirianImg from "./Mirian_Torres.jpg";

const teamIndex = [
  {
    name: "Mirta Torres",
    role: "MirtaRole",
    photo: mirtaImg,
    linkedin: "https://www.linkedin.com/in/mirtabarbaratorresberdeguez/",
  },
  {
    name: "Harlley Dantas",
    role: "HarlleyRole",
    photo: harlleyImg,
    linkedin: "https://www.linkedin.com/in/harlley-hauradou/",
  },
  {
    name: "Nicolas da Matta",
    role: "NicolasRole",
    photo: nicolasImg,
    linkedin: "https://www.linkedin.com/in/nicolas-da-matta/",
  },
  {
    name: "Henrique de Andrade",
    role: "HenriqueRole",
    photo: henriqueImg,
    linkedin: "https://www.linkedin.com/in/henriqueccda/",
  },
  {
    name: "Nathalia Leite",
    role: "NathaliaRole",
    photo: nathaliaImg,
    linkedin: "https://www.linkedin.com/in/nathalia-leite-a5839719b/",
  },
  {
    name: "Mirian Torres",
    role: "MirianRole",
    photo: mirianImg,
    linkedin: "",
  },
];

export default teamIndex;
