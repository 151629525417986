import { isotipoAzul } from "../../img/index";

const Loading = () => {
  return (
    <div className="text-center">
      <img
        src={isotipoAzul}
        alt="dosimagem_logo"
        className="img-fluid loading"
      />
    </div>
  );
};

export default Loading;
