// CSS
import styles from "./Footer.module.css";
// Components
import {
  FaFacebook,
  FaLinkedin,
  FaInstagram,
  FaWhatsapp,
} from "react-icons/fa";
import { NavLink } from "react-router-dom";
// Hooks
import { usePickTerm } from "../../hooks/usePickTerm";
// Images
import { logoImg } from "../../img/index";

const Footer = () => {
  const footerSocialLinks = [
    {
      icon: <FaFacebook size={24} />,
      link: "https://www.facebook.com/Dosimagem",
    },
    {
      icon: <FaLinkedin size={24} />,
      link: "https://www.linkedin.com/company/dosimagem/",
    },
    {
      icon: <FaInstagram size={24} />,
      link: "https://www.instagram.com/dosimagemoficial/",
    },
    {
      icon: <FaWhatsapp size={24} />,
      link: "https://api.whatsapp.com/send?phone=5521965232386",
    },
  ];

  const termLink = usePickTerm();

  const footerLinks = [
    {
      name: "FAQ",
      link: "/faq",
    },
    {
      name: "Termos de uso",
      link: termLink,
    },
  ];

  return (
    <footer className="container-fluid" id={styles["footer"]}>
      <div className="row">
        <div className="col-12 mb-1" id={styles["social-media-footer"]}>
          {footerSocialLinks.map(({ icon, link }, idx) => (
            <a
              key={idx}
              href={link}
              target="_blank"
              rel="noopener noreferrer"
              role="button"
              data-mdb-ripple-color="dark"
            >
              {icon}
            </a>
          ))}
        </div>
        <div className="col-6" id={styles["useful-links"]}>
          <ul className="list-unstyled mb-0 p-1">
            {footerLinks.map(({ name, link }, idx) =>
              name !== "Termos de uso" ? (
                <li key={idx} className="text-white">
                  <NavLink to={link}>{name}</NavLink>
                </li>
              ) : (
                <li key={idx} className="text-white">
                  <a
                    href={link}
                    target="_blank"
                    rel="noopener noreferrer"
                    role="button"
                    data-mdb-ripple-color="dark"
                  >
                    {name}
                  </a>
                </li>
              )
            )}
          </ul>
        </div>
        <div className="col-6 mb-0 p-1" id={styles["company-address"]}>
          <p>
            Dosimagem Inc. Dosimagem Ltda
            <br />
            R. Hélio de Almeida, S/nº - Sala 10 - Cidade Universitária da
            Universidade Federal do Rio de Janeiro, Ilha do Fundão - RJ
            <br />
            CEP: 21941-614
            <br />
            CNPJ: 42.789.152/0001-65
          </p>
        </div>
        <div
          className="col-12 text-center text-white p-2"
          id={styles["copyright"]}
        >
          <img src={logoImg} alt="logo-dosimagem" />
          <span>&copy; 2021 Dosimagem</span>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
