// Hooks
import { useSelector } from "react-redux";
import { useFormatInput } from "../../hooks/useFormatInput";
import { useTranslation } from "react-i18next";
// Components
import { Link } from "react-router-dom";
import Loading from "../General/Loading";
import Message from "../General/Message";
// Redux
import { selectCurrentUser } from "../../redux/auth/authSlice";
import { useGetSignaturesQuery } from "../../redux/services/signatureService";
// Icons
import { RiBillFill } from "react-icons/ri";

const MySignatures = () => {
  const currentUser = useSelector(selectCurrentUser);
  const {
    data: signatures = [],
    isLoading,
    error,
  } = useGetSignaturesQuery(currentUser.id);

  const { formatDateToBr } = useFormatInput();
  const { t } = useTranslation();

  return (
    <>
      {!isLoading && signatures && signatures.length === 0 && (
        <div className="message warning">
          <p>
            {t("EmptySignaturesMessage")}{" "}
            <span>
              <Link className="text-warning" to="/plans">
                {t("planos")}
              </Link>
              .
            </span>
          </p>
        </div>
      )}
      <div className="row justify-content-evenly align-items-center my-5">
        {error && <Message msg={error} type="error" />}
        {isLoading && <Loading />}
        {!isLoading &&
          signatures &&
          signatures.length !== 0 &&
          signatures.map(
            ({
              id,
              plan,
              benefits,
              price,
              hiredPeriod,
              testPeriod,
              activated,
              billFile,
            }) => (
              <div
                key={id}
                className="col-lg-5 align-self-center border rounded shadow my-1 me-1 px-3 py-2"
              >
                <h4 className="bg-dark bg-gradient text-white p-2">
                  {t(plan)}
                </h4>
                <div className="fs-6 text-start">
                  <span className="fw-bold">{t("Preço")}: </span>
                  {Number(price).toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                  })}
                </div>
                {hiredPeriod && (
                  <>
                    <div className="fs-6 text-start">
                      <span className="fw-bold">{t("Início")}: </span>{" "}
                      {formatDateToBr(hiredPeriod.initial)}
                    </div>
                    <div className="fs-6 text-start">
                      <span className="fw-bold">
                        {t("Próximo vencimento")}:{" "}
                      </span>{" "}
                      {formatDateToBr(hiredPeriod.end)}
                    </div>
                  </>
                )}
                {testPeriod && (
                  <>
                    <div className="fs-6 text-start">
                      <span className="fw-bold">
                        {t("Fim do período de teste")}:{" "}
                      </span>{" "}
                      {formatDateToBr(testPeriod.end)}
                    </div>
                  </>
                )}
                <div className="border-top pt-2 m-2 ">
                  <ul className="list-group">
                    {activated &&
                      benefits.map((benefit) => (
                        <Link
                          key={benefit.id}
                          className="text-decoration-none"
                          to={benefit.uri}
                        >
                          <li className="list-group-item list-group-item-action">
                            {benefit.name}
                          </li>
                        </Link>
                      ))}
                    {!activated &&
                      benefits.map((benefit) => (
                        <li key={benefit.id} className="list-group-item">
                          {benefit.name}
                        </li>
                      ))}
                  </ul>
                </div>
                <div className="row justify-content-around m-2 border-top pt-3">
                  <div className="col">
                    {activated ? (
                      <Link
                        to={billFile}
                        className="btn btn-dark px-2 disabled"
                      >
                        <RiBillFill size={24} />
                      </Link>
                    ) : (
                      <Link to={billFile} className="btn btn-dark px-2">
                        <RiBillFill size={24} />
                      </Link>
                    )}
                  </div>
                </div>
              </div>
            )
          )}
      </div>
    </>
  );
};

export default MySignatures;
