import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import I18NextHttpBackend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import translationEN from "./locales/en/translation.json";
import translationES from "./locales/es/translation.json";
import translationPT from "./locales/pt/translation.json";

const fallbackLng = ["pt-BR"];
const availableLanguages = ["pt-BR", "en-US", "es"];

const resources = {
  en: {
    translation: translationEN,
  },
  es: {
    translation: translationES,
  },
  pt: {
    translation: translationPT,
  },
};

i18n
  .use(I18NextHttpBackend) // Load translations using http (default                                               public/assets/locals/en/translations)
  .use(LanguageDetector) // Detect user language
  .use(initReactI18next) // Pass the i18n instance to react-i18next.
  .init({
    resources,
    fallbackLng,

    detection: {
      checkWhitelist: true, // Options for language detection
    },

    debug: false,

    whitelist: availableLanguages,

    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
