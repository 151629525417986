// Redux
import { configureStore } from "@reduxjs/toolkit";
// Api config
import { apiSlice } from "./api/apiSlice";
// Auth config
import authSlice from "./auth/authSlice";
// Slices
import dashboardSlice from "./slices/dashboardSlice";

export const store = configureStore({
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,
    auth: authSlice,
    dashboard: dashboardSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(
      apiSlice.middleware
    ),
});
