import { apiSlice } from "../api/apiSlice";
import { requestConfig } from "../config/config";

export const orderService = apiSlice.injectEndpoints({
  tagTypes: ["Order", "Analysis"],
  endpoints: (builder) => ({
    getOrders: builder.query({
      query: (userId) => requestConfig(`/users/${userId}/orders/`, "GET", null),
      transformResponse: (response) => response.row,
      providesTags: ["Order", "Analysis"],
    }),
    getOrderById: builder.query({
      query: ({ userId, orderId }) =>
        requestConfig(`/users/${userId}/orders/${orderId}/`, "GET", null),
      providesTags: ["Order", "Analysis"],
    }),
  }),
});

export const { useGetOrdersQuery, useGetOrderByIdQuery } = orderService;
