import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../redux/auth/authSlice";

export const useAuth = () => {
  const user = useSelector(selectCurrentUser);

  const [auth, setAuth] = useState(false);

  useEffect(() => {
    if (user && Object.keys(user).length === 2 && user.id) {
      setAuth(true);
    } else {
      setAuth(false);
    }
  }, [user]);

  return { auth };
};
