export const useFormatInput = () => {
  const formatCPF = (cpf) => {
    if (cpf) {
      const cleanCPF = cleanInput(cpf);
      const firstGroup = cleanCPF.slice(0, 3);
      const secondGroup = cleanCPF.slice(3, 6);
      const thirdGroup = cleanCPF.slice(6, 9);
      const lastGroup = cleanCPF.slice(9, 11);
      const newCPF = `${firstGroup}.${secondGroup}.${thirdGroup}-${lastGroup}`;
      return cleanCPF.length === 11 ? newCPF : cleanCPF;
    }
  };

  const formatCNPJ = (cnpj) => {
    if (cnpj) {
      const cleanCNPJ = cleanInput(cnpj);
      const firstGroup = cleanCNPJ.slice(0, 2);
      const secondGroup = cleanCNPJ.slice(2, 5);
      const thirdGroup = cleanCNPJ.slice(5, 8);
      const fourthGroup = cleanCNPJ.slice(8, 12);
      const lastGroup = cleanCNPJ.slice(12, 14);
      const newCNPJ = `${firstGroup}.${secondGroup}.${thirdGroup}/${fourthGroup}-${lastGroup}`;
      return cleanCNPJ.length === 14 ? newCNPJ : cleanCNPJ;
    }
  };

  const formatPhone = (phone) => {
    if (phone) {
      const cleanPhone = cleanInput(phone);
      const ddi = cleanPhone.slice(0, 2)
      const ddd = cleanPhone.slice(2, 4);
      const firstGroup = cleanPhone.slice(4, 9);
      const secondGroup = cleanPhone.slice(9, 14);
      const newPhone = `${ddi}(${ddd})${firstGroup}-${secondGroup}`;
      return cleanPhone.length === 13 ? newPhone : cleanPhone;
    }
  };

  const formatDateToFront = (date) => {
    const year = date.split("/")[2];
    const month = date.split("/")[1];
    const day = date.split("/")[0];
    const newDate = `${year}-${month}-${day}`;
    return newDate;
  };

  const formatDateToBr = (date) => {
    if (date) {
      const year = date.split("-")[0];
      const month = date.split("-")[1];
      const day = date.split("-")[2];
      const newDate = `${day}/${month}/${year}`;
      return newDate;
    }
  };

  const cleanInput = (input) => {
    if (input) {
      const newInput = input.replace(/\.|\D+/g, "");
      return newInput;
    }
  };

  return {
    formatCPF,
    formatCNPJ,
    formatPhone,
    formatDateToFront,
    formatDateToBr,
    cleanInput,
  };
};
