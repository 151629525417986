// Hooks
import { useSelector } from "react-redux";
// Components
import Loading from "../../components/General/Loading";
import Message from "../../components/General/Message";
// Redux
import { selectCurrentUser } from "../../redux/auth/authSlice";
import {
  useGetNotificationsQuery,
  useCheckNotificationMutation,
} from "../../redux/services/notificationService";
// CSS
import styles from "./Notifications.module.css";

const Notifications = () => {
  const notificationClasses = {
    processing: "alert-warning",
    update: "alert-info",
    success: "alert-success",
    error: "alert-danger",
  };

  const currentUser = useSelector(selectCurrentUser);
  const {
    data: notifications = [],
    error,
    isLoading,
  } = useGetNotificationsQuery(currentUser.id);
  const [checkNotification] = useCheckNotificationMutation();

  const handleCheck = async (id) => {
    const notificationData = { userId: currentUser.id, notificationId: id };
    await checkNotification(notificationData);
  };
  console.log(notifications)
  return (
    <div className={styles.notification_container}>
      {isLoading && <Loading />}
      {error && <Message msg={error} type="error" />}
      {!isLoading && notifications.length === 0 && (
        <Message
          msg="Você não tem nenhuma notificação..."
          type="warning"
          timeout={false}
        />
      )}
      {!isLoading &&
        notifications.length !== 0 &&
        notifications.map(
          ({ id, message, kind, checked }) =>
            !checked && (
              <div
                key={id}
                className={`alert ${notificationClasses[kind]} alert-dismissible fade show`}
                role="alert"
              >
                <span>{message}</span>
                <span className="form-check-reverse">
                  <button
                    type="button"
                    className="btn-close"
                    onClick={() => handleCheck(id)}
                  ></button>
                </span>
              </div>
            )
        )}
      {!isLoading &&
        notifications.length !== 0 &&
        notifications.map(
          ({ id, message, checked }) =>
            checked && (
              <div key={id} className={`alert alert-secondary`} role="alert">
                <span>{message}</span>
              </div>
            )
        )}
    </div>
  );
};

export default Notifications;
