import dosiClin from "./dosi_clin.jpg";
import dosiPreclin from "./preclinica.jpg";
import modelComp from "./modelagem_comp.jpg";
import radiosino from "./radiosinoviortese.jpg";
import segQuant from "./seg_quant.jpg";
import teamImg from "./time.png";
import securityImg from "./security.png";
import expensesImg from "./expenses.png";
import teamHome from "./team_home.png";

// Home services
const services = [
  {
    id: "dosi-clin",
    title: "ServiceDosiClinTitle",
    body: "ServiceDosiClinBody",
    img: dosiClin,
    shortDescription: "ServiceDosiClinShortDescription",
    longDescription: [
      "ServiceDosiClinLongDescription1",
      "ServiceDosiClinLongDescription2",
    ],
    productDescription: "ServiceDosiClinProductDescription",
  },
  {
    id: "dosi-preclin",
    title: "ServiceDosiPreclinTitle",
    body: "ServiceDosiPreclinBody",
    img: dosiPreclin,
    shortDescription: "ServiceDosiPreclinShortDescription",
    longDescription: ["ServiceDosiPreclinLongDescription"],
    productDescription: "ServiceDosiPreclinProductDescription",
  },
  {
    id: "model-comp",
    title: "ServiceModelCompTitle",
    body: "ServiceModelCompBody",
    img: modelComp,
    shortDescription: "ServiceModelCompShortDescription",
    longDescription: [
      "ServiceModelCompLongDescription1",
      "ServiceModelCompLongDescription2",
      "ServiceModelCompLongDescription3",
      "ServiceModelCompLongDescription4",
    ],
    productDescription: "ServiceModelProductDescription",
  },
  {
    id: "seg-quant",
    title: "ServiceSegQuantTitle",
    body: "ServiceSegQuantBody",
    img: segQuant,
    shortDescription: "ServiceSegQuantShortDescription",
    longDescription: [
      "ServiceSegQuantLongDescription1",
      "ServiceSegQuantLongDescription2",
    ],
    productDescription: "ServiceSegQuantProductDescription",
  },
  {
    id: "radiosino",
    title: "ServiceRadiosinoTitle",
    body: "ServiceRadiosinoBody",
    img: radiosino,
    shortDescription: "ServiceRadiosinoShortDescription",
    longDescription: [
      "ServiceRadiosinoLongDescription1",
      "ServiceRadiosinoLongDescription2",
      "ServiceRadiosinoLongDescription3",
    ],
    productDescription: "ServiceRadiosinoProductDescription",
  },
];
// Company pros
const companyPros = [
  {
    title: "CompanyProSecurityTitle",
    text: "CompanyProSecurityText",
    img: securityImg,
  },
  {
    title: "CompanyProExpenseTitle",
    text: "CompanyProExpenseText",
    img: expensesImg,
  },
  {
    title: "CompanyProTimeTitle",
    text: "CompanyProTimeText",
    img: teamImg,
  },
];

export { services, companyPros, teamImg, teamHome };
