import { apiSlice } from "../api/apiSlice";
import { requestConfig } from "../config/config";

const signatureService = apiSlice.injectEndpoints({
  tagTypes: ["Signature"],
  endpoints: (builder) => ({
    getSignatures: builder.query({
      query: (userId) =>
        requestConfig(`/users/${userId}/signatures/`, "GET", null),
      transformResponse: (response) => response.row,
      providesTags: ["Signature"],
    }),
    createSignature: builder.mutation({
      query: ({ userId, data }) =>
        requestConfig(`users/${userId}/signatures`, "POST", data),
      invalidatesTags: ["Signature"],
    }),
  }),
});

export const { useGetSignaturesQuery, useCreateSignatureMutation } =
  signatureService;
