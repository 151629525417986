import firstSlide from "./carousel_slide_1.png";
import secondSlide from "./carousel_slide_2.png";
import thirdSlide from "./carousel_slide_3.png";

const carouselIndex = [
  {
    img: firstSlide,
    caption: "FirstSlideCaption",
    logo: true,
  },
  {
    img: secondSlide,
    caption:
      "SecondSlideCaption",
    logo: true,
  },
  {
    img: thirdSlide,
    caption:
      "ThirdSlideCaption",
    logo: true,
  },
];

export default carouselIndex;