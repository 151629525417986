import { apiSlice } from "../api/apiSlice";
import { requestConfig } from "../config/config";

const notificationService = apiSlice.injectEndpoints({
  tagTypes: ["Notification"],
  endpoints: (builder) => ({
    getNotifications: builder.query({
      query: (userId) =>
        requestConfig(`/users/${userId}/notifications/`, "GET", null),
      transformResponse: (response) => response.row,
      providesTags: ["Notification"],
    }),
    checkNotification: builder.mutation({
      query: ({ userId, notificationId }) =>
        requestConfig(
          `/users/${userId}/notifications/${notificationId}/toogle_check/`,
          "POST",
          null
        ),
      invalidatesTags: ["Notification"],
    }),
  }),
});

export const { useGetNotificationsQuery, useCheckNotificationMutation } =
  notificationService;
